@import "./helpers/index";

// HOME PAGE
.home-page {
  .hero {
    & {
      z-index: 2;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      @media #{$laptop} {
        border: 60px solid #fff;
      }
      .home-canvas-ctn {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 800px;
        height: 800px;
        opacity: 0;
        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .hotspot {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        cursor: default;
        @media #{$laptop} {
          display: block;
        }
        &:link {
          cursor: pointer;
        }
      }
    }
    &-bg .hero-image{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      transition: opacity 0.6s ease-out;
    }
    &-content {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 100%;
      height: 100%;
      padding: 0 20px;
      pointer-events: none;
      @media #{$tablet} {
        width: 80%;
        max-width: 525px;
        height: 70%;
      }
      @media #{$laptop} {
        max-width: 650px;
      }
      &-bg {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media #{$tablet} {
          background: #fff;
        }
      }
    }
    &-copy {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0,-75%);
      width: 100%;
      text-align: center;
      @media #{$tablet} and (min-height: 800px) {
        transform: translate(0,-100%);
      }
    }
    &-subheading {
      padding-bottom: 5px;
      h2 {
        font-family: $font-family--secondary;
        font-weight: $font-weight--bold;
        font-size: 16px;
        letter-spacing: 0.7em;
      }
    }
    &-title {
      h1 {
        max-width: 5em;
        font-size: 70px;
        line-height: 1em;
        text-transform: uppercase;
        @media #{$tablet} {
          font-size: 85px;
        }
        @media #{$desktop} {
          font-size: 104px;
        }
      }
    }
    &-article {
      position: absolute;
      top: 75%;
      left: 0;
      width: 100%;
      text-align: center;
      display: none;
      @media #{$laptop} {
        .no-touchevents &,
        .desktop-touch &{
          display: block;
        }
      }
      &-subheading {
        font-family: $font-family--secondary;
        font-size: 13px;
        font-weight: $font-weight--bold;
        text-transform: uppercase;
        letter-spacing: 0.7em;
        padding-bottom: 5px;
        &:before {
          content:"";
          display:inline;
          padding-right: 0.7em;
        }
      }
      &-title {
        font-size: 29px;
        line-height: 1.2em;
        max-width: 10em;
        margin: 0 auto;
      }
    }
    &-date,
    &-time {
      & {
        position: absolute;
        z-index: 3;
        top: 100%;
        width: 0;
        height: 0;
        @media #{$tablet} {
          top: 50%;
        }
      }
      &-text {
        position: absolute;
        display: block;
        height: 1em;
        line-height: 1em;
        font-family: $font-family--secondary;
        font-weight: $font-weight--bold;
        font-size: 16px;
        letter-spacing: 0.7em;
      }
    }
    &-date {
      & {
        display: none;
        @media #{$tablet} {
          display: block;
          left: 0;
          transform: rotate(-90deg);
        }
      }
      &-text {
        left: 0;
        transform: translate(-50%,-50%);
      }
    }
    &-time {
      & {
        right: 50%;
        @media #{$tablet} {
          right: 0;
          transform: rotate(90deg);
        }
      }
      &-text {
        display: block;
        right: 0;
        transform: translate(50%,-50%);
        @media #{$tablet} {
          transform: translate(50%,-50%);
        }
      }
    }
  }
  .color-scale {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%,0);
    width: 220px;
    @media #{$tablet} {
      display: block;
    }
    div {
      float: left;
      width: 20px;
      height: 20px;
      &:nth-of-type(1) {background: #000000;}
      &:nth-of-type(2) {background: #3e3e3e;}
      &:nth-of-type(3) {background: #58585a;}
      &:nth-of-type(4) {background: #6d6e70;}
      &:nth-of-type(5) {background: #818286;}
      &:nth-of-type(6) {background: #949599;}
      &:nth-of-type(7) {background: #a8a9ad;}
      &:nth-of-type(8) {background: #bcbdc1;}
      &:nth-of-type(9) {background: #d1d2d4;}
      &:nth-of-type(10) {background: #e7e7e9;}
      &:nth-of-type(11) {
        background: #fff;
        border: 1px solid #000;
      }
    }
  }
  main {
    overflow: hidden;
  }
}
.home-bg {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: $animation-transition;
  background-image: url(../assets/img/bg-pattern.png);
  background-size: 15px 15px;
}
.manifesto {
  z-index: 2;
  position: relative;
  width: 100%;
  padding: 80px 0 130px;
  text-align: center;
  @media #{$tablet} {
    padding: 100px 0 180px;
  }
  @media #{$laptop} {
    border-top: 80px solid #fff;
  }
  &-heading {
    padding-bottom: 40px;
    font-family: $font-family--secondary;
    font-size: 16px;
    font-weight: $font-weight--bold;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    z-index: 1;
    position: relative;
    font-size: 26px;
    line-height: 1.35em;
    letter-spacing: 0.025em;
    max-width: 20em;
    padding: 0 20px;
    @media #{$tablet} {
      font-size: 35px;
    }
    @media #{$laptop} {
      font-size: 45px;
    }
    span {
      display: inline;
      &.not {
        text-decoration: line-through;
      }
    }
  }
  .no-touchevents &,
  .desktop-touch &{
    p {
      span {
        opacity: 0;
        transition: 0.3s ease-out;
        transition-delay: 0s;
      }
    }
  }
  p.trigger-active span {
    opacity: 1;
    @for $i from 1 through 10 {
      &:nth-of-type(#{$i}) { transition-delay: #{$i * 0.15s}; }
    }
  }
}
.toc {
  & {
    z-index: 1;
    position: relative;
    width: 100%;
    color: #fff;
    background-color: #000;
  }
  &-heading {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    color: $color--primary;
    font-family: $font-family--secondary;
    font-size: 16px;
    font-weight: $font-weight--bold;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    text-align: center;
    @media #{$tablet} {
      top: -80px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%,0);
      display: block;
      width: 2px;
      height: 60px;
      margin-top: 10px;
      background-color: #000;
      @media #{$tablet} {}
    }
  }
  &-bg {
    &-ctn {
      z-index: 0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
    & {
      z-index: 1;
      position: absolute;
      top: 40%;
      left: 50%;
      width: 300px;
      height: auto;
      transform: translate(-50%,-50%);
      @media #{$tablet} {
        top: 50%;
        width: 500px;
      }
      @media #{$laptop} {
        width: 700px;
      }
    }
    &:before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
    }
    .smiley-face-svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%) scale(0.75);
      width: 160%;
      height: 160%;
      fill: #fff;
      opacity: 0.04;
      &:nth-of-type(1) {
        margin-left: 3px;
      }
      &:nth-of-type(2) {
        margin-left: -3px;
      }
    }
    #home-canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:100%;
    }
  }
  &-verticaltext {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    white-space: nowrap;
    transform-origin: 0 50%;
    transform: rotate(90deg) translate(-15px,0);
    font-size: 8px;
    opacity: 0.2;
    line-height: 30px;
    @extend %font-smoothing;
    span span {
      display: none;
      @media #{$laptop} {
        display: inline;
      }
    }
    .crop-mark {
      margin-left: 10px;
      stroke: #fff;
      position: relative;
    }
  }
  &-content {
    &-ctn {
      z-index: 3;
      position: relative;
      padding-top: 50vh;
      padding-bottom: 100px;
      @media #{$laptop} {
        padding-bottom: 200px;
      }
      @media #{$desktop} {
        padding-bottom: 300px;
      }
      .no-touchevents &,
      .desktop-touch & {
        padding-bottom: 200px;
        @media #{$laptop} {
          padding-bottom: 300px;
        }
        @media #{$desktop} {
          padding-bottom: 300px;
        }
      }
    }
    & {
      position: relative;
      z-index: 2;
      width: 100%;
      margin: 125px auto 0;
      margin: 0 auto;
      @media #{$tablet} {
        width: 50%;
        margin: 175px auto 0;
        margin: 0 auto;
      }
      @media #{$laptop} {
        margin: 250px auto 0;
        margin: 0 auto;
      }
    }
  }
  &-item {
    display: inline-block;
    &-ctn {
      text-align: center;
      @media #{$tablet} {
        &:nth-of-type(2n+1) {
          transform: translate(50%,0);
          text-align: left;
        }
        &:nth-of-type(2n) {
          transform: translate(-50%,0);
          text-align: right;
        }
      }
    }
    &.trigger-active {
      text-decoration: line-through;
    }
  }
  &-title {
    font-size: 45px;
    line-height: 1.1em;
    max-width: 6em;
    @media #{$tablet} {
      max-width: 8em;
      // font-size: 65px;
    }
    @media #{$laptop} {
      // font-size: 65px;
    }
    @media #{$mac} {
      font-size: 65px;
    }
  }
  &-author {
    font-family: $font-family--secondary;
    font-size: 13px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }
}
